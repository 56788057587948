<!--
 * @Author       : JiangChao
 * @Date         : 2022-11-28 14:33:54
 * @LastEditors  : JiangChao
 * @LastEditTime : 2024-08-17 11:35:43
 * @Description  : 新的登录页面，SMS,GOOGLE,APPLE
-->
<template>
    <div class="container">
        <div class="bg" :style="{ minHeight: boxheight }">
            <img
                class="logo"
                src="../assets/imgs/png/icon_register_logo.png"
                alt=""
            />
            <p>旅電</p>
            <div>
                <div class="input-moudle">
                    <van-field
                        class="input"
                        v-model="phone"
                        type="digit"
                        clearable
                        placeholder="請輸入手機號碼"
                    >
                    </van-field>
                    <van-field
                        class="input"
                        v-model="smsCode"
                        type="digit"
                        maxlength="6"
                        placeholder="請輸入簡訊驗證碼"
                    >
                        <template #right-icon>
                            <div @click="$_getVerifyCode()" class="send">
                                <span class="line">|</span
                                >{{
                                    counting ? `${timerCount}s` : "發送驗證碼"
                                }}
                            </div>
                        </template>
                    </van-field>
                </div>
            </div>

            <div class="read-moudle">
                <van-checkbox v-model="isCheck" @change="readChange">
                    <template #icon="props">
                        <van-image
                            width="18px"
                            height="18px"
                            :src="props.checked ? activeIcon : inactiveIcon"
                        />
                    </template>
                </van-checkbox>

                <span class="read">我已經閱讀了</span>
                <span class="clause" @click="goPrivacyPolicy"
                    >《服務協議和隱私規定》</span
                >
            </div>

            <div class="login-way">
                <div class="sms-way" @click="login">登錄</div>
            </div>

            <div class="plug-inn">
                <van-divider>其他方式登錄</van-divider>
                <div class="other-way">
                    <div class="apple-btn-style" @click="checkApple">
                        Sign in with Apple
                    </div>
                    <!-- <div
                        id="appleid-signin"
                        @click="loginByApple"
                        data-mode="center-align"
                        data-type="sign-in"
                        data-color="black"
                        data-border="false"
                        v-if="false"
                        data-border-radius="15"
                        data-width="200"
                        data-height="32"
                    ></div> -->
                    <!-- <vue-apple-signin
                     id="appleid-signin"
                    ></vue-apple-signin> -->
                    <div class="g-signin-button" @click="signInWithGoogle">
                        Sign in with Google
                    </div>
                </div>
                <img
                    style="width: 60px; height: 10px"
                    src="../assets/imgs/png/pluginn.png"
                    alt=""
                />
            </div>
        </div>
    </div>
</template>
<script>
var client;
var access_token;
// document.addEventListener("AppleIDSignInOnSuccess", (event) => {
//     console.log("AppleIDSignInOnSuccess---event: ", event.detail.data);
// });
// document.addEventListener("AppleIDSignInOnFailure", (event) => {
//     console.log("AppleIDSignInOnFailure---event: ", event.detail.error);
// });
import { handleErr } from "@/helpers";
// import { Toast } from "vant";
// import { Util } from "@/utils/utils";

// Import the functions you need from the SDKs you need
// import { initializeApp } from "firebase/app";
// import { getAnalytics, logEvent } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
// const firebaseConfig = {
//     apiKey: "AIzaSyDxPJAZaIpyrZmtRzzNNjCXTcTBTOJjYOM",
//     authDomain: "lvdian-339303.firebaseapp.com",
//     projectId: "lvdian-339303",
//     storageBucket: "lvdian-339303.appspot.com",
//     messagingSenderId: "408081585217",
//     appId: "1:408081585217:web:05c6bf18d23cf7770622c0",
//     measurementId: "G-6S44RLZW9K",
// };

// // Initialize Firebase
// const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);
// console.log("analytics: ", analytics);

export default {
    name: "",
    data() {
        return {
            activeIcon: require("@/assets/imgs/png/icon_agree_selected.png"),
            inactiveIcon: require("@/assets/imgs/png/icon_login_default.png"),
            smsCode: "",
            phone: "",
            isCheck: false,
            timerCount: 0,
            counting: false, // 是否计时中
            yzBtnEnable: true,
            interval1: null,
            isLoading: false,
            googleInfo: {},
            appleInfo: {},
            idToken: "",
            code: "",
            // clickApple: false, //是否点击了apple登录
        };
    },
    beforeCreate() {
        const token = window.$cookies.get("token");
        if (token) {
            this.$axios.defaults.headers.common["token"] = token;
            this.$axios.defaults.headers.common["appType"] = "PHONE";
            this.$router.push("map");
        }
    },
    methods: {
        clearIntervalHandel() {
            clearInterval(this.interval1);
            this.yzBtnEnable = true;
            this.counting = false;
        },
        async $_getVerifyCode() {
            if (this.isLoading) return;
            if (!this.phone) {
                handleErr({ app: this.$parent, msg: "請輸入電話號碼" });
                return;
            }
            if (!this.isCheck) {
                handleErr({
                    app: this.$parent,
                    msg: "請先閱讀服務協議和隱私規定！",
                });
                return;
            }

            const nationCode = 886;
            const nationMobile = this.phone;
            this.isLoading = true;
            let timer = 60;
            try {
                this.$toast.loading({
                    duration: 0, // 持续展示 toast
                    message: "加載中",
                    forbidClick: true,
                });
                const res = await this.$axios.get(
                    `/customer/mobile/code/${nationCode}/${nationMobile}`
                );
                const status = res.data.statusCode;
                if (status === 500) {
                    throw { msg: "系統繁忙，請稍後再試" };
                } else if (status === 200) {
                    this.$toast.clear();
                    this.interval1 = setInterval(() => {
                        timer -= 1;
                        if (timer <= 0) {
                            this.clearIntervalHandel();
                        } else {
                            this.timerCount = timer;
                            this.counting = true;
                            this.yzBtnEnable = false;
                        }
                    }, 1000);
                    throw { msg: "已送出驗證碼" };
                } else {
                    this.$toast.clear();
                    throw { msg: res.data.msg };
                }
            } catch (error) {
                this.$toast.clear();
                handleErr({ ...error, app: this.$parent });
            }
            this.isLoading = false;
        },
        readChange(val) {
            console.log("val: ", val);
            if (val) {
                this.isCheck = true;
            } else {
                this.isCheck = false;
            }
        },
        goPrivacyPolicy() {
            this.$router.push("/Agreement");
        },
        // 定义一个函数来记录事件
        // logCustomEvent(name, params) {
        //     logEvent(analytics, name, params);
        // },

        async login() {
            if (!this.isCheck) {
                handleErr({
                    app: this.$parent,
                    msg: "請先閱讀服務協議和隱私規定！",
                });
                return;
            }
            if (!this.phone) {
                handleErr({ app: this.$parent, msg: "請輸入電話號碼" });
                return;
            }
            if (!this.smsCode) {
                handleErr({ app: this.$parent, msg: "請輸入簡訊驗證碼" });
                return;
            }

            const nationCode = 886;
            const nationMobile = this.phone;
            this.$axios.defaults.headers.common["appType"] = "PHONE";
            try {
                const res = await this.$axios.post(`customer/loginByCode`, {
                    code: this.smsCode,
                    nationCode: nationCode,
                    username: nationMobile,
                });
                // console.log("res: ", res);
                if (res.data.success) {
                    console.log(res, "reslogin");
                    // const eventParams = {
                    //     button_id: "sms_signup_button",
                    //     location: "header",
                    // };
                    // this.logCustomEvent("H5_SMS_LOGIN", eventParams);
                    const userToken = res.data.result.token;
                    const userMobile = res.data.result.mobile;
                    const email = res.data.result.email;
                    const avatar = res.data.result.avatar;
                    const nickName = res.data.result.name;
                    const id = res.data.result.userId;

                    // 手机号、id、邮箱、头像、昵称保存到localStorage里
                    localStorage.setItem("user_mobile", userMobile);
                    localStorage.setItem("id", id);
                    localStorage.setItem("email", email);
                    localStorage.setItem("avatar", avatar);
                    localStorage.setItem("nickName", nickName);

                    //token保存到cookies里
                    window.$cookies.set("token", userToken);
                    //保存到请求头
                    this.$axios.defaults.headers.common["token"] = userToken;
                    this.$axios.defaults.headers.common["appType"] = "PHONE";
                    // await this.$_bindWithLine(userToken, userMobile);
                    //将后端传递的token存到浏览器的cookie中，key=token，过期时间30天
                    if (!email) {
                        this.$router.push("emailCheck");
                    } else {
                        this.$router.push("map");
                    }
                    // console.log("axios", this.$axios);
                    // alert(`liff getOS: ${this.$liff.getOS()}`);
                } else {
                    throw res;
                }
            } catch (error) {
                handleErr({ ...error, app: this.$parent });
            }
        },
        initAppleLoginConfig() {
            window.AppleID.auth.init({
                clientId: "com.lvdian.new.web", //com.lvelect.sa
                scope: "openid name email",
                redirectURI: "https://sa.lvelect.com/redirect/forWeb3Return", //https://c.lvelect.com:447/map
                state: "STATE",
                nonce: "",
                usePopup: false,
                response_type: "code id_token", // 固定内容
            });
        },
        async checkApple() {
            if (!this.isCheck) {
                handleErr({
                    app: this.$parent,
                    msg: "請先閱讀服務協議和隱私規定！",
                });
                return;
            }

            window.AppleID.auth.init({
                clientId: "com.lvdian.new.web",
                scope: "name email",
                redirectURI: "https://sa.lvelect.com/redirect/forWeb3Return",
            });
            // 调用这个方法将直接 运行 window.location.href 进行页面跳转
            try {
                const data = window.AppleID.auth.signIn();
                console.log("data----: ", data);
                // Handle successful response.
            } catch (error) {
                console.log("error---: ", error);
                // Handle error.
            }
            // this.clickApple = true;
        },
        loginByApple() {
            // try {
            //     const data = window.AppleID.auth.signIn();
            //     console.log("data----: ", data);
            //     // Handle successful response.
            // } catch (error) {
            //     console.log("error---: ", error);
            //     // Handle error.
            // }
        },
        initGoogleLoginConfig() {
            client = window.google.accounts.oauth2.initTokenClient({
                // 408081585217-mcviqkh7dou3bqsasdrhmca4tptn0gtr.apps.googleusercontent.com
                client_id:
                    "333399775660-ospqb5r3sgm1qcmrunmi7eeedltn6mps.apps.googleusercontent.com",
                scope: "profile",
                callback: (tokenResponse) => {
                    // console.log("tokenResponse: ", tokenResponse);
                    access_token = tokenResponse.access_token;
                    // console.log("access_token: ", access_token);
                    this.getGoogleInfo(access_token);
                },
            });
        },
        signInWithGoogle() {
            if (!this.isCheck) {
                handleErr({
                    app: this.$parent,
                    msg: "請先閱讀服務協議和隱私規定！",
                });
                return;
            } else {
                client.requestAccessToken();
            }
        },
        async getGoogleInfo(token) {
            this.$toast.loading({
                duration: 0, // 持续展示 toast
                message: "請稍後...",
                forbidClick: true,
            });
            const res = await this.$axios.get(
                `https://www.googleapis.com/oauth2/v2/userinfo?`,
                {
                    params: {
                        access_token: token,
                    },
                }
            );
            // console.log("getGoogleInfo---: ", res);

            // console.log("google-user-info---: ", JSON.stringify(res.data));
            this.googleInfo = res.data;

            this.updateUI(this.googleInfo);
        },
        async updateUI(data) {
            var that = this;
            try {
                const res = await this.$axios.post("/customer/googleExist", {
                    appCode: data.id,
                    email: data.email,
                });
                // console.log("res: ", res);
                // {"msg":"success","success":true,"result":true,"statusCode":200}
                if (res.data.success) {
                    if (res.data.result) {
                        this.loginByGoogleEmail(this.googleInfo);
                    } else {
                        // console.log(JSON.stringify(that.googleInfo));
                        this.$toast.clear();
                        this.$router.push({
                            name: "BindMobile",
                            params: {
                                appCode: that.googleInfo.id,
                                email: that.googleInfo.email,
                                name: that.googleInfo.name,
                            },
                        });
                    }
                } else {
                    throw res;
                }
            } catch (error) {
                handleErr({ ...error, app: this.$parent });
            }
        },
        async loginByGoogleEmail(data) {
            try {
                const res = await this.$axios.post(
                    "/customer/loginByGoogleNewWeb",
                    {
                        appCode: data.id,
                        email: data.email,
                        nationMobile: "",
                        smsCode: "",
                        type: "",
                    }
                );
                // console.log("loginByGoogleEmail: ", res);
                if (res.data.success) {
                    // const eventParams = {
                    //     button_id: "google_signup_button",
                    //     location: "header",
                    // };
                    // this.logCustomEvent("H5_GOOGLE_LOGIN", eventParams);
                    window.$cookies.set("token", res.data.result.token);
                    this.$axios.defaults.headers.common["token"] =
                        res.data.result.token;
                    this.$axios.defaults.headers.common["appType"] = "PHONE";
                    this.$toast.clear();
                    this.$router.push("map");
                } else {
                    throw res;
                }
            } catch (error) {
                handleErr({ ...error, app: this.$parent });
            }
        },

        async $_bindWithLine(userToken, userMobile) {
            try {
                const lineAccessToken = await this.$liff.getAccessToken();
                const res = await this.$axios({
                    url: "/line/bind",
                    method: "post",
                    // baseURL: "https://login.pluginn.com.tw/api",
                    baseURL: "https://login.pluginn-web.com/api",
                    data: {
                        user_token: userToken,
                        user_mobile: userMobile,
                        line_access_token: lineAccessToken,
                    },
                });
                if (res.data.success) {
                    await this.$axios.post("customer/upUserInfo", {
                        nickName: res.data.line_user_name,
                    });
                } else {
                    console.log("get line display name failed");
                }
            } catch (error) {
                handleErr({ ...error, app: this.$parent });
            }
        },

        async updateAppleUI() {
            this.$toast.loading({
                duration: 0, // 持续展示 toast
                message: "請稍後...",
                forbidClick: true,
            });
            try {
                const res = await this.$axios.post("/customer/appleExist", {
                    idToken: this.idToken,
                    appCode: this.code,
                });
                // console.log("updateAppleUI-res: ", res);
                // {"msg":"success","success":true,"result":true,"statusCode":200}
                if (res.data.success) {
                    if (res.data.result) {
                        this.loginByAppleEmail();
                    } else {
                        this.$toast.clear();
                        this.$router.push({
                            name: "BindMobile2",
                            params: {
                                appCode: this.code,
                                idToken: this.idToken,
                            },
                        });
                    }
                } else {
                    throw res;
                }
            } catch (error) {
                handleErr({ ...error, app: this.$parent });
            }
        },
        async loginByAppleEmail() {
            try {
                const res = await this.$axios.post(
                    "/customer/loginByAppleNewWeb",
                    {
                        appCode: this.code,
                        idToken: this.idToken,
                        nationMobile: "",
                        smsCode: "",
                        type: "",
                    }
                );
                // console.log("loginByAppleEmail: ", res);
                if (res.data.success) {
                    // const eventParams = {
                    //     button_id: "apple_signup_button",
                    //     location: "header",
                    // };
                    // this.logCustomEvent("H5_APPLE_LOGIN", eventParams);
                    window.$cookies.set("token", res.data.result.token);
                    this.$axios.defaults.headers.common["token"] =
                        res.data.result.token;
                    this.$axios.defaults.headers.common["appType"] = "PHONE";
                    this.$toast.clear();
                    this.$router.push("map");
                } else {
                    throw res;
                }
            } catch (error) {
                handleErr({ ...error, app: this.$parent });
            }
        },
    },
    props: {},
    components: {},
    computed: {
        boxheight() {
            return `${window.innerHeight}px`;
        },
    },
    mounted() {
        window.$cookies.set("token", "");
        // if (this.clickApple) {
        //如果点击了apple登录按钮，才执行这一步
        this.code = this.$route.query.code;
        // console.log("this.code: ", this.code);
        this.idToken = this.$route.query.idToken;
        // console.log("this.idToken: ", this.idToken);
        if (this.idToken || this.code) {
            this.updateAppleUI();
        }
        // }

        this.initGoogleLoginConfig();
    },
    created() {},
};
</script>

<style scoped lang="less">
.bg {
    background: linear-gradient(360deg, #eda143, #f9e266);
    text-align: center;
    .logo {
        width: 100px;
        margin-top: 60px;
        height: 100px;
    }
    .plug-inn {
        position: fixed;
        bottom: 0;
        width: 100%;
        margin-bottom: 20px;
        text-align: center;
        .other-way {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin: 20px;
            justify-content: space-around;
            color: #333333;
            font-weight: 500;
        }
    }
    .input-moudle {
        margin-right: 22px;
        margin-left: 22px;
        .input {
            margin-top: 24px;
            background: #f9f9f9;
            border-radius: 22px;
            margin-bottom: 7px;
            // position: static;
        }
        .line {
            opacity: 0.68;
            width: 1px;
            color: #e1e5e9;
            border-radius: 8px;
            margin-right: 20px;
        }
        .send {
            font-size: 14px;
            font-family: SourceHanSansCN, SourceHanSansCN-Normal;
            font-weight: Normal;
            text-align: center;
            color: #333333;
            line-height: 18px;
        }
    }
}
.read-moudle {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 22px;
    margin-top: 20px;
    .clause {
        font-size: 12px;
        font-family: SourceHanSansCN, SourceHanSansCN-Regular;
        font-weight: 400;
        text-align: left;
        color: #a1a1a1;
        line-height: 12px;
    }
    .read {
        font-size: 12px;
        font-family: SourceHanSansCN, SourceHanSansCN-Regular;
        font-weight: 400;
        text-align: left;
        color: #666666;
        margin-left: 6px;
        line-height: 18px;
    }
}
.login-way {
    .sms-way {
        background: #2c394e;
        border-radius: 25px;
        height: 50px;
        color: #ffffff;
        line-height: 50px;
        margin: 20px;
    }
}
.g-signin-button {
    /* This is where you control how the button looks. Be creative! */
    display: inline-block;
    text-align: center;
    border-radius: 4px;
    background-color: #3c82f7;
    color: #fff;
    height: 32px;
    width: 200px;
    line-height: 32px;
    margin-top: 20px;
    font-size: 14px;
}
.apple-btn-style {
    width: 200px;
    color: white;
    background: black;
    border-radius: 4px;
    font-size: 14px;
    height: 32px;
    display: inline-block;
    line-height: 32px;
    text-align: center;
}
</style>
